import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Balance, BalanceSkin } from '@pt/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, DIALOG_DATA, DialogRef, IconModule, trackById } from '@pinup-teams/common';
import { environment } from '@pt/environment';
import { ImageLoaderDirective } from '@pt/directives';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, IconModule, ReactiveFormsModule, ImageLoaderDirective],
  selector: 'pu-balance-skin-form',
  templateUrl: './balance-skin-form.component.html',
  styleUrls: ['./balance-skin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceSkinFormComponent {
  assetsHost = environment.assetsHost;
  selectedSkin = this.data.balance.skin;
  balance = this.data.balance;
  skins = this.data.skins;

  trackById = trackById;

  constructor(
    private _dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: { skins: BalanceSkin[]; balance: Balance },
  ) {
  }

  selectSkin(skin: BalanceSkin) {
    this.selectedSkin = skin;
  }

  apply() {
    this._dialogRef.close({
      skinId: this.selectedSkin.id,
      balanceType: this.balance.balanceType,
    });
  }

  close(): void {
    this._dialogRef.close();
  }
}
